<template>
  <div style="height: 100vh">
    <pdf-view
      :uri="uri"
      width="100%"
      height="100%"
      @download-file="downloadFile"
    />
  </div>
</template>

<script>
import PdfView from 'Components/Pdf/PdfView'

export default {
  components: {
    PdfView,
  },
  data() {
    return {
      uri: '',
    }
  },
  created() {
    this.getPdfUrl()
  },
  methods: {
    getPdfUrl() {
      try {
        const payload = {
          localId: this.$route.params.id,
          version: this.$route.params.version,
        }
        this.$store.dispatch('getPdfUrl', payload).then((response) => {
          if (response.status === 'success') {
            this.uri = response.url
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    downloadFile() {
      try {
        const localId = this.$route.params.id
        const version = this.$route.params.version
        if (localId) {
          const payload = {
            localId: localId,
            version: version,
          }
          this.$store.dispatch('downloadInvoicePdfFile', payload)
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
